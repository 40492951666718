import React, { PureComponent, Fragment } from 'react';
import { Link as GLink } from 'gatsby';
// @ts-ignore
import { Link } from 'react-scroll';
import { MenuHolder, MenuButton, MenuItem } from './MenuMobile.style';

export interface IItems {
  name: string;
  link: string;
}

export interface IProps {
  isLandingPage: boolean;
  items: IItems[];
}

export interface IState {
  isMenuOpen: boolean;
}

export default class MenuMobile extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isMenuOpen: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  render() {
    const { items, isLandingPage } = this.props;
    const { isMenuOpen } = this.state;

    const menuItems = items.map(item =>
      isLandingPage ? (
        <Link
          key={item.link}
          to={`#${item.link}`}
          onClick={this.toggleMenu}
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          <MenuItem key={item.link}>{item.name}</MenuItem>
        </Link>
      ) : (
        <GLink to="/">
          <MenuItem key={item.link}>{item.name}</MenuItem>
        </GLink>
      )
    );

    return (
      <Fragment>
        <MenuHolder isOpen={isMenuOpen}>{menuItems}</MenuHolder>
        <MenuButton isOpen={isMenuOpen} onClick={this.toggleMenu} />
      </Fragment>
    );
  }
}
