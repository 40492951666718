import styled from 'styled-components';
import colors from '../../../constants/style/colors';

export const SectionContent = styled.section`
  max-width: 1600px;
  width: 90vw;
  margin: 0 auto;
  background-color: ${colors.white};
  padding-top: 1px;
  position: relative;
`;
