import React, { PureComponent } from 'react';
import { Link as GLink } from 'gatsby';
// @ts-ignore
import { Link } from 'react-scroll';
import { StaticQuery, graphql } from 'gatsby';
import { setConfiguration } from 'react-grid-system';
import Logo from '../../Elements/Logo';
import MenuMobile from '../../Elements/MenuMobile';
import {
  HeaderHolder,
  HeaderContent,
  MenuHolder,
  MenuItem,
  MenuBackground,
  LogoHolder
} from './Header.style';

setConfiguration({ gridColumns: 24 });

export interface Props {
  isLandingPage?: boolean;
  allContentfulMenu: {
    edges: {
      node: {
        name: string;
        order: number;
        callToAction: boolean | null;
      };
    }[];
  };
}

export interface State {
  isLoaded: boolean;
  isMobile: boolean;
  isHeaderTop: boolean;
  isWallpaperScrollEnd: boolean;
}

class Header extends PureComponent<Props, State> {
  public node: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoaded: false,
      isMobile: false,
      isHeaderTop: false,
      isWallpaperScrollEnd: false
    };

    this.setMobileState = this.setMobileState.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.setMobileState();

    const { isLandingPage } = this.props;

    window.addEventListener('resize', this.setMobileState);
    window.addEventListener('scroll', this.handleScroll, true);

    if (!isLandingPage) {
      this.setState({ isHeaderTop: true, isWallpaperScrollEnd: true });
    }

    this.setState({ isLoaded: true });
  }

  setMobileState() {
    if (!this.node) {
      return;
    }

    const isMobile = this.node.clientWidth < 813;

    if (this.state.isMobile !== isMobile) {
      this.setState({ isMobile });
    }
  }

  handleScroll() {
    const { isLandingPage } = this.props;
    const headerHeight = 60;
    const elementWallpaper = document.getElementById('WALLPAPER');
    const wallpaperHeight = elementWallpaper
      ? elementWallpaper.clientHeight - headerHeight
      : 0;

    if (!isLandingPage) {
      return;
    }

    if (window.scrollY > wallpaperHeight && !this.state.isWallpaperScrollEnd) {
      this.setState({ isWallpaperScrollEnd: true });
    } else if (
      window.scrollY < wallpaperHeight &&
      this.state.isWallpaperScrollEnd
    ) {
      this.setState({ isWallpaperScrollEnd: false });
    }

    if (window.scrollY > headerHeight && !this.state.isHeaderTop) {
      this.setState({ isHeaderTop: true });
    } else if (window.scrollY < headerHeight && this.state.isHeaderTop) {
      this.setState({ isHeaderTop: false });
    }
  }

  render() {
    const {
      allContentfulMenu: { edges },
      isLandingPage
    } = this.props;
    const {
      isLoaded,
      isMobile,
      isHeaderTop,
      isWallpaperScrollEnd
    } = this.state;
    const items = edges
      .sort((a, b) => a.node.order - b.node.order)
      .filter(item => item.node.order !== 0)
      .map(item =>
        isLandingPage ? (
          <Link
            key={item.node.name}
            to={`#${item.node.name}`}
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            <MenuItem
              key={item.node.order}
              isCallToAction={Boolean(item.node.callToAction)}
            >
              {item.node.name}
            </MenuItem>
          </Link>
        ) : (
          <GLink to="/" key={item.node.name}>
            <MenuItem
              key={item.node.order}
              isCallToAction={Boolean(item.node.callToAction)}
            >
              {item.node.name}
            </MenuItem>
          </GLink>
        )
      );
    const menuItems = edges.map(item => ({
      name: item.node.name,
      link: item.node.name
    }));

    const HeaderLogo = () => (
      <LogoHolder>
        {!isMobile && (
          <Link to={`#INTRO`} spy={true} smooth={true} duration={500}>
            <Logo color="DEFAULT" />
          </Link>
        )}
        {isMobile && (
          <MenuMobile isLandingPage={isLandingPage} items={menuItems} />
        )}
      </LogoHolder>
    );

    return (
      <div
        ref={node => {
          this.node = node;
        }}
      >
        {isLoaded && (
          <>
            <HeaderHolder isSticky={isHeaderTop}>
              <HeaderContent>
                <HeaderLogo />
                {!isMobile && <MenuHolder>{items}</MenuHolder>}
              </HeaderContent>
            </HeaderHolder>
            <MenuBackground
              isSticky={isHeaderTop}
              isActive={isWallpaperScrollEnd}
            />
          </>
        )}
      </div>
    );
  }
}

export default ({
  isLandingPage
}: {
  isLandingPage: Props['isLandingPage'];
}) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulMenu {
          edges {
            node {
              name
              order
              callToAction
            }
          }
        }
      }
    `}
    render={data => (
      <Header
        isLandingPage={isLandingPage}
        allContentfulMenu={data.allContentfulMenu}
      />
    )}
  />
);
