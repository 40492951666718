import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../constants/style/colors';
import Logo from '../../Elements/Logo';

export interface IProps extends React.Props<any> {
  isOpen: boolean;
  onClick: any;
}
export const MenuHolder = styled('div')`
  position: fixed;
  display: ${({ isOpen }: IProps) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.grey};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const MenuButtonHolder = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
`;
export const MenuButtonIconHolder = styled('div')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  left: 45px;
  width: 40px;
  height: 40px;
`;
export const MenuButton = ({ isOpen, onClick }: IProps) => {
  const icon = isOpen ? (
    <FontAwesomeIcon icon="times" color={colors.white} />
  ) : (
    <FontAwesomeIcon icon="bars" color={colors.white} />
  );

  return (
    <MenuButtonHolder onClick={onClick}>
      <Logo color="DEFAULT" />
      <MenuButtonIconHolder>{icon}</MenuButtonIconHolder>
    </MenuButtonHolder>
  );
};
export const MenuItem = styled('div')`
  text-align: center;
  padding: 10px;
  color: ${colors.white};
  font-size: 20px;
`;
