import styled from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faTimes,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';

library.add(faBars, faTimes, faMapMarkerAlt);

export const LayoutCon = styled.div`
  /* max-width: 980px; */
`;
