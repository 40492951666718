import React from 'react';
import colors from '../../../constants/style/colors';

export const LogoDefault = () => (
  <svg height="100" width="100">
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,100) scale(0.02)">
      <path
        style={{
          fill: 'none',
          strokeWidth: '5.66930008',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M10 3610h3600V10H10z"
      />
      <path
        style={{
          fill: '#ffbe0b',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d="M1413.95 910.137c0 9.949 3.4 18.332 10.23 25.429 6.91 7.008 15.28 10.493 25.15 10.493 9.95 0 18.24-3.485 25.15-10.493 6.81-7.097 10.23-15.48 10.23-25.429 0-9.668-3.42-17.965-10.23-24.692-6.91-6.726-15.2-10.125-25.15-10.125-9.87 0-18.24 3.399-25.15 10.125-6.83 6.727-10.23 15.024-10.23 24.692"
      />
      <path
        style={{
          fill: '#ffbe0b',
          fillOpacity: 1,
          fillRule: 'evenodd',
          stroke: 'none',
        }}
        d="M2974.75 2744.68H775.949v-771.54h70.735v700.8H2904.02V946.059H1573.04V875.32h1401.71v1869.36"
      />
      <path
        style={{
          fill: '#ffffff',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: colors.grey,
          strokeWidth: 5,
        }}
        d="M1675.08 1670.74h160.03c0-2.74.09-6.61.28-11.61.56-13.1.84-21.87.84-26.49 0-56.86-16.31-101.09-48.84-132.49-32.62-31.49-78.55-47.24-137.77-47.24-33.19 0-62.71 4.9-88.64 14.61-25.93 9.82-48.75 24.53-68.17 44.23-19.15 19.52-33.85 42.44-44.04 68.84-10.28 26.31-15.37 54.5-15.37 84.48 0 30.56 5.19 58.75 15.56 84.59 10.47 25.74 25.84 48.47 46.3 68.18 19.71 19.33 42.81 34.04 69.31 44.22 26.5 10.09 54.88 15.19 85.05 15.19 31.78 0 60.36-5.86 85.43-17.45 25.09-11.6 47.53-29.33 67.24-53.28l-47.9-36.21c-13.68 15.84-29.05 27.63-46.21 35.37-17.07 7.73-36.21 11.59-57.43 11.59-43.84 0-79.58-14.05-107.3-42.15-27.73-28.1-41.59-64.59-41.59-109.48 0-45.73 13.86-82.79 41.59-111.17 27.72-28.48 63.65-42.72 107.87-42.72 37.53 0 67.14 8.77 88.64 26.22 21.59 17.53 32.34 41.3 32.34 71.57v5.75h-97.22v55.45m-646.87 197.46h65.06v-245.65c0-36.4 8.39-63.84 25.28-82.14 16.88-18.38 42.06-27.53 75.62-27.53 33.29 0 58.37 9.15 75.25 27.53 16.88 18.3 25.27 45.74 25.27 82.14v245.65h65.63v-251.69c0-54.69-13.76-95.71-41.39-122.87-27.63-27.15-69.31-40.73-124.95-40.73-55.63 0-97.12 13.58-124.56 40.64-27.44 27.06-41.21 68.09-41.21 122.96v251.69m-246.585-404.54v185.11L645.273 1868.2h69.122l84.863-137.3c2.074-3.58 4.527-8.11 7.644-13.87 3.016-5.65 6.028-11.88 9.145-18.48 2.637 6.41 5.371 12.54 8.203 18.2 2.828 5.75 5.844 10.93 8.953 15.75l86.942 135.7h65.73l-139.184-219.43v-185.11h-65.066M961.07 891.934v440.916l269.61-259.23c7.31-7.32 14.72-15.13 22.35-23.42 7.52-8.32 15.42-17.72 23.74-28.1v294.13h63.51V875.316l-274.87 264.094c-7.32 7.2-14.45 14.73-21.27 22.63-6.92 7.92-13.35 16.24-19.56 24.94V891.934h-63.51m-315.824 0v424.296h236.797v-58.73H714.109v-106.24h167.934v-60.51H714.109V953.664h167.934v-61.73H645.246"
      />
    </g>
  </svg>
);

export const LogoBlack = () => (
  <svg height="100" width="100">
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,100) scale(0.02)">
      <path
        style={{
          fill: 'none',
          strokeWidth: '5.66930008',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M10 3610h3600V10H10z"
      />
      <path
        style={{
          fill: '#ffbe0b',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d="M1413.95 910.137c0 9.949 3.4 18.332 10.23 25.429 6.91 7.008 15.28 10.493 25.15 10.493 9.95 0 18.24-3.485 25.15-10.493 6.81-7.097 10.23-15.48 10.23-25.429 0-9.668-3.42-17.965-10.23-24.692-6.91-6.726-15.2-10.125-25.15-10.125-9.87 0-18.24 3.399-25.15 10.125-6.83 6.727-10.23 15.024-10.23 24.692"
      />
      <path
        style={{
          fill: '#ffbe0b',
          fillOpacity: 1,
          fillRule: 'evenodd',
          stroke: 'none',
        }}
        d="M2974.75 2744.68H775.949v-771.54h70.735v700.8H2904.02V946.059H1573.04V875.32h1401.71v1869.36"
      />
      <path
        style={{
          fill: colors.black,
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d="M1675.08 1670.74h160.03c0-2.74.09-6.61.28-11.61.56-13.1.84-21.87.84-26.49 0-56.86-16.31-101.09-48.84-132.49-32.62-31.49-78.55-47.24-137.77-47.24-33.19 0-62.71 4.9-88.64 14.61-25.93 9.82-48.75 24.53-68.17 44.23-19.15 19.52-33.85 42.44-44.04 68.84-10.28 26.31-15.37 54.5-15.37 84.48 0 30.56 5.19 58.75 15.56 84.59 10.47 25.74 25.84 48.47 46.3 68.18 19.71 19.33 42.81 34.04 69.31 44.22 26.5 10.09 54.88 15.19 85.05 15.19 31.78 0 60.36-5.86 85.43-17.45 25.09-11.6 47.53-29.33 67.24-53.28l-47.9-36.21c-13.68 15.84-29.05 27.63-46.21 35.37-17.07 7.73-36.21 11.59-57.43 11.59-43.84 0-79.58-14.05-107.3-42.15-27.73-28.1-41.59-64.59-41.59-109.48 0-45.73 13.86-82.79 41.59-111.17 27.72-28.48 63.65-42.72 107.87-42.72 37.53 0 67.14 8.77 88.64 26.22 21.59 17.53 32.34 41.3 32.34 71.57v5.75h-97.22v55.45m-646.87 197.46h65.06v-245.65c0-36.4 8.39-63.84 25.28-82.14 16.88-18.38 42.06-27.53 75.62-27.53 33.29 0 58.37 9.15 75.25 27.53 16.88 18.3 25.27 45.74 25.27 82.14v245.65h65.63v-251.69c0-54.69-13.76-95.71-41.39-122.87-27.63-27.15-69.31-40.73-124.95-40.73-55.63 0-97.12 13.58-124.56 40.64-27.44 27.06-41.21 68.09-41.21 122.96v251.69m-246.585-404.54v185.11L645.273 1868.2h69.122l84.863-137.3c2.074-3.58 4.527-8.11 7.644-13.87 3.016-5.65 6.028-11.88 9.145-18.48 2.637 6.41 5.371 12.54 8.203 18.2 2.828 5.75 5.844 10.93 8.953 15.75l86.942 135.7h65.73l-139.184-219.43v-185.11h-65.066M961.07 891.934v440.916l269.61-259.23c7.31-7.32 14.72-15.13 22.35-23.42 7.52-8.32 15.42-17.72 23.74-28.1v294.13h63.51V875.316l-274.87 264.094c-7.32 7.2-14.45 14.73-21.27 22.63-6.92 7.92-13.35 16.24-19.56 24.94V891.934h-63.51m-315.824 0v424.296h236.797v-58.73H714.109v-106.24h167.934v-60.51H714.109V953.664h167.934v-61.73H645.246"
      />
    </g>
  </svg>
);
